import { LoadingButton } from "@mui/lab";
import {
  FilledInput,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Slide,
  TextField,
} from "@mui/material";
import { useLoginComponent } from "./use-login";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export const Login = () => {
  const {
    formik,
    showOrHide,
    handleMouseDownPassword,
    handleShoworHide,
    login,
  } = useLoginComponent();

  return (
    <Slide in={true} direction="up">
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <Paper
          sx={(theme) => ({
            padding: "20px",
            margin: "5px",
            maxWidth: "300px",
            background: theme.palette.background.paper,
          })}
          variant="outlined"
        >
          <form onSubmit={formik.handleSubmit} noValidate>
            <TextField
              id="login"
              name="login"
              variant="filled"
              label="Seu Usuário:"
              fullWidth={true}
              margin="normal"
              value={formik.values.login}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.login && Boolean(formik.errors.login)}
              helperText={formik.touched.login && formik.errors.login}
              disabled={formik.isSubmitting}
            />
            <FormControl
              margin="normal"
              fullWidth={true}
              variant="filled"
              error={formik.touched.senha && Boolean(formik.errors.senha)}
            >
              <InputLabel htmlFor="senha">Sua senha</InputLabel>
              <FilledInput
                id="senha"
                name="senha"
                type={showOrHide ? "text" : "password"}
                fullWidth={true}
                value={formik.values.senha}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.isSubmitting}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShoworHide}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showOrHide ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.senha && (
                <FormHelperText>{formik.errors.senha}</FormHelperText>
              )}
            </FormControl>
            <LoadingButton
              variant="text"
              type="submit"
              color="primary"
              size="large"
              loading={login.mutation.isPending}
              endIcon={<LoginIcon />}
              fullWidth={true}
            >
              Login
            </LoadingButton>
          </form>
        </Paper>
      </div>
    </Slide>
  );
};
