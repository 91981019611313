import { useQuery } from "@tanstack/react-query";
import { useEnvironment } from "usecases/environment";
import { useLogger } from "usecases/logger";
import { useSaurusAuthorization } from "usecases/saurusAuthorization";
import { fetchAllPagesData } from "common/fetch-all-pages";
import { UseContractsProps } from "./types";
import { ContractCollection } from "./models";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useNavigate } from "react-router-dom";

export const useContracts = ({ filters }: UseContractsProps) => {
  const environment = useEnvironment();
  const saurus = useSaurusAuthorization();
  const navigate = useNavigate();
  const logger = useLogger();
  const retrieve = saurus.authorize(
    async (authorization: string): Promise<ContractCollection> => {
      const options = {
        method: "GET",
        headers: {
          Authorization: authorization,
        },
      };

      const page = 1;
      const pageSize = 100;

      const query = {
        DataInicial: format(
          !!filters
            ? new Date(
                filters.start.year,
                filters.start.month - 1,
                filters.start.day
              )
            : startOfMonth(new Date()),
          "yyyy/MM/dd"
        ),
        DataFinal: format(
          new Date(filters.end.year, filters.end.month - 1, filters.end.day) ||
            endOfMonth(new Date()),
          "yyyy/MM/dd"
        ),
        page: page.toString(),
        pageSize: pageSize.toString(),
      };

      logger.info(`Filters: ${JSON.stringify(filters)}`);
      logger.info(`Query: ${new URLSearchParams(query).toString()}`);

      try {
        const result = await fetchAllPagesData(
          `${environment.saurusTouchOneHost}/fatura?${new URLSearchParams(
            query
          ).toString()}`,
          options
        );

        return new ContractCollection(result as any);
      } catch (e) {
        navigate("/");
        return undefined as any;
      }
    }
  );

  const query = useQuery({
    queryKey: [
      `contracts${filters.end.day}-${filters.end.month}-${filters.end.year}-${filters.start.day}-${filters.start.month}-${filters.start.year}`,
    ],
    queryFn: retrieve,
    initialData: new ContractCollection([]),
  });

  const isLoading = query.isFetching || query.isLoading;

  return {
    retrieve,
    query,
    filters,
    isLoading,
  };
};
