import { DateFilter } from "common";
import { parsePhone } from "common/parse-phone";
import { addMinutes, format } from "date-fns";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAgreement } from "usecases/agreement";
import {
  CustomerData,
  CustomersFetch,
  UseCustomersFilters,
  useCustomers,
} from "usecases/customers";
import { DefaultDashboardFilter } from "usecases/dashboard";
import { usePayContract } from "usecases/pay-contract";

export const useCustomersPage = () => {
  const location = useLocation();
  const agreement = useAgreement();
  const pay = usePayContract({});
  const fetchType: CustomersFetch = location.state?.fetchType || "all";
  const dateFilter: DateFilter =
    location.state?.filters || DefaultDashboardFilter;
  const filters: UseCustomersFilters = {
    ...dateFilter,
    page: 1,
    pageSize: 20,
  };

  const customers = useCustomers({
    fetchType,
    filters,
  });

  const onWhatsAppClick = useCallback(
    async (
      event: React.MouseEvent<HTMLTableCellElement> | undefined,
      data: CustomerData
    ) => {
      const results = await Promise.all(
        data.contracts.map((contract) =>
          pay.retrievePayments
            .mutateAsync({
              id: contract.json.id,
              paymentId: "7c9a7ba5-93b5-4532-8e5b-63337cd7b45c",
            })
            .catch((err) => null)
        )
      );

      const text = results.reduce((message, current) => {
        if (!current) {
          return message;
        }

        if (current.pixCopiaCola) {
          message += `\n\n\n * PIX Expira: ${format(
            current?.dhExpiracaoPix || addMinutes(new Date(), 30),
            "HH:mm"
          )} * \n\n\n ${current?.pixCopiaCola} \n\n\n`;
        }

        if (current.url) {
          message += `\n\n\n Boleto: \n\n ${current.url} \n\n`;
        }

        return message;
      }, ``);

      if (!text) {
        toast("Não foi possível obter o código pix", {
          type: "error",
        });
        return;
      }

      const message = `Olá ${data.nome} aqui está o link da suas últimas faturas \n ${text} `;

      window.open(
        `https://api.whatsapp.com/send/?phone=${encodeURI(
          parsePhone(data.telefone)
        )}&text=${encodeURI(message)}`
      );
    },
    [pay]
  );

  const sendEmail = useCallback(
    (
      event: React.MouseEvent<HTMLTableCellElement> | undefined,
      data: CustomerData
    ) => {
      window.open(`mailto: ${data.email}`);
    },
    []
  );

  const handleMakeDeal = useCallback(
    (
      event: React.MouseEvent<HTMLTableCellElement> | undefined,
      data: CustomerData
    ) => {
      agreement.makeDeal.mutate(data.id);
    },
    [agreement]
  );

  return {
    location,
    fetchType,
    filters,
    isLoading: customers.isLoading,
    data: customers.data,
    selectedApps: location.state?.selectedApps,
    seletedStates: location.state?.selectedStates,
    onWhatsAppClick,
    handleMakeDeal,
    sendEmail,
  };
};
