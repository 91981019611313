import { isAfter } from "date-fns";
import { SaurusPaginationResponse } from "common/saurus-pagination";
import { ContractResponse } from "./types";
import { groupBy } from "lodash";
import { PersonResponse } from "usecases/person";

export class Contract {
  readonly json: ContractResponse;
  readonly pending: Boolean;
  readonly paid: Boolean;
  person: PersonResponse | undefined;

  constructor(json: ContractResponse) {
    this.json = json;
    this.pending = this.isPending();
    this.paid = this.isPaid();
  }

  static build(json: ContractResponse): Contract {
    return new Contract(json);
  }

  isPending(): boolean {
    return (
      !this.json.dataPagamento && [4, 6].includes(this.json.situacao) === false
    );
  }

  withBonusOrCanceled(): boolean {
    return [4, 6].includes(this.json.situacao);
  }

  isCanceled(): boolean {
    return this.json.situacao === 4;
  }

  isBonus(): boolean {
    return this.json.situacao === 6 || this.json.situacao === 4;
  }

  isPaid(): boolean {
    return !!this.json.dataPagamento;
  }

  isLate(): boolean {
    return (
      !!this.json.dataPagamento &&
      isAfter(this.json.dataPagamento, this.json.dataVencimento)
    );
  }

  loadPerson(person: PersonResponse): void {
    this.person = person;
  }

  getPerson(): PersonResponse {
    if (!this.person) {
      throw new Error(`Você não carregou a pessoa.`);
    }

    return this.person;
  }

  cost(): number {
    return this.json.valorCusto || 0;
  }

  yieldValue(): number {
    return (this.json.valor || 0) - this.cost();
  }
}

export const emptyContracts = (): ContractCollection => {
  return new ContractCollection([
    {
      list: [],
      pageIndex: 1,
      pageSize: 10,
      somatorios: {
        Encargo: 0,
        MultaJuros: 0,
        Valor: 0,
        ValorCusto: 0,
        ValorCustoOriginal: 0,
        ValorPago: 0,
      },
      totalPages: 1,
      totalResults: 0,
    },
  ]);
};

export class ContractCollection {
  readonly pagination: SaurusPaginationResponse<ContractResponse>[];
  readonly items: Contract[];

  constructor(pagination: SaurusPaginationResponse<ContractResponse>[]) {
    this.pagination = pagination;
    this.items =
      pagination
        .flatMap((item) => item.list)
        .map((item) => new Contract(item)) || [];
  }

  countAll(): number {
    return this.getItems().length;
  }

  countPending(): number {
    return this.getItems().filter((item) => item.isPending()).length || 0;
  }

  countPaids(): number {
    return this.getItems().filter((item) => item.isPaid()).length || 0;
  }

  getPending(): Contract[] {
    return this.getItems().filter(
      (item) =>
        item.isPending() && [4, 6].includes(item.json.situacao) === false
    );
  }

  getItems(): Contract[] {
    return this.items || [];
  }

  getPaids(): Contract[] {
    return this.getItems().filter((item) => item.isPaid());
  }

  getBonus(): Contract[] {
    return this.getItems().filter(
      (item) => item.isBonus() || item.isCanceled()
    );
  }

  getCanceled(): Contract[] {
    return this.getItems().filter((item) => item.isCanceled());
  }

  getAll(): Contract[] {
    return this.getItems() || [];
  }

  getPaidAmount(): number {
    return this.getPaids().reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  getPendingAmount(): number {
    return this.getPending().reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  getAllAmount(): number {
    return this.getAll().reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  countCustomersPaid(): number {
    return Object.keys(
      groupBy(this.getPaids().map((item) => item.json.pagadorCpfCnpj))
    ).length;
  }

  countCustomersPending(): number {
    return Object.keys(
      groupBy(this.getPending().map((item) => item.json.pagadorCpfCnpj))
    ).length;
  }

  countCustomers(): number {
    return Object.keys(
      groupBy(this.getAll().map((item) => item.json.pagadorCpfCnpj))
    ).length;
  }

  getApps(): string[] {
    return (
      Object.keys(
        groupBy(this.getAll().map((item) => item.json.sistemaDescricao))
      ) || []
    );
  }
}
