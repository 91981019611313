import {
  Alert,
  Button,
  AlertColor,
  Typography,
  Grid,
  AlertPropsColorOverrides,
} from "@mui/material";
import { MouseEventHandler } from "react";
import { useFormat } from "usecases/format";
import { OverridableStringUnion } from "@mui/types";
import ReactTextTransition from "react-text-transition";

export interface SummaryProps<T> {
  category: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
  info: string;
  count?: number | undefined;
  amount?: number | undefined;
  cost?: number | undefined;
  yieldValue?: number | undefined;
  customers?: number | undefined;
  canceledCount?: number | undefined;
  bonus?: number | undefined;
  onClick?: MouseEventHandler<T> | undefined;
  yieldLabel: string;
}

export const Summary = ({
  category,
  info,
  count = 0,
  amount = 0,
  customers = 0,
  cost = 0,
  yieldValue = 0,
  onClick,
  yieldLabel,
}: SummaryProps<any>) => {
  const { toCurrencyFormat } = useFormat();

  return (
    <Alert
      severity={category}
      sx={(theme) => ({
        border: `1px solid ${theme.palette[category].light}`,
        height: "100%",
        display: "flex",
        alignItems: "center",
      })}
      action={
        <Button color="inherit" size="small" onClick={onClick}>
          <b>Ver</b>
        </Button>
      }
    >
      <Typography gutterBottom={false} style={{ margin: 0 }} variant="h6">
        <b>{info}</b>
      </Typography>
      <Grid container display="inline" spacing={2} width="100%">
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="subtitle2"
            align="left"
            fontStyle="italic"
          >
            Contratos
          </Typography>
          <Typography
            component="div"
            variant="subtitle2"
            fontWeight="bold"
            align="left"
          >
            <ReactTextTransition
              inline
              direction="up"
              springConfig={{ tension: 300, friction: 50 }}
            >
              {count}
            </ReactTextTransition>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="subtitle2"
            align="left"
            fontStyle="italic"
          >
            Clientes
          </Typography>
          <Typography
            component="div"
            variant="subtitle2"
            fontWeight="bold"
            align="left"
          >
            <ReactTextTransition
              inline
              direction="up"
              springConfig={{ tension: 300, friction: 50 }}
            >
              {customers}
            </ReactTextTransition>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="subtitle2"
            align="left"
            fontStyle="italic"
          >
            Valor
          </Typography>
          <Typography
            component="div"
            variant="subtitle2"
            fontWeight="bold"
            align="left"
          >
            <ReactTextTransition
              inline
              direction="up"
              springConfig={{ tension: 300, friction: 50 }}
            >
              {toCurrencyFormat(amount)}
            </ReactTextTransition>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            component="div"
            variant="subtitle2"
            align="left"
            fontStyle="italic"
          >
            Custo
          </Typography>
          <Typography
            component="div"
            variant="subtitle2"
            fontWeight="bold"
            align="left"
          >
            <ReactTextTransition
              inline
              direction="up"
              springConfig={{ tension: 300, friction: 50 }}
            >
              {toCurrencyFormat(cost)}
            </ReactTextTransition>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="div"
            variant="subtitle2"
            align="left"
            fontStyle="italic"
          >
            {yieldLabel}
          </Typography>
          <Typography
            component="div"
            variant="subtitle2"
            fontWeight="bold"
            align="left"
          >
            <ReactTextTransition
              inline
              direction="up"
              springConfig={{ tension: 300, friction: 50 }}
            >
              {toCurrencyFormat(yieldValue)}
            </ReactTextTransition>
          </Typography>
        </Grid>
      </Grid>
    </Alert>
  );
};
