import { Container, Grid, Typography } from "@mui/material";
import { ViewDetailsComponentProps } from "components/paginated-table";
import { CustomerData } from "usecases/customers";
import { PrintInformation } from "components/print-information";
import { Contracts } from "./contracts/contracts";

export interface CustomerPageProps
  extends ViewDetailsComponentProps<CustomerData> {}

export const CustomerPage = ({ row }: CustomerPageProps) => {
  return (
    <Container
      sx={{
        padding: "20px 5px",
        width: "100%",
      }}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography gutterBottom textAlign="left" variant="h5" component="h2">
            <b>{row.nome}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {row.hasInfos ? (
            <PrintInformation
              fullWidth
              label="Endereço"
              value={`${row.informacoes?.logradouro}, ${row.informacoes?.numero} - ${row.informacoes?.complemento} ${row.informacoes?.bairro}, ${row.informacoes?.municipio} ${row.informacoes?.obs}`}
            />
          ) : (
            <Typography gutterBottom variant="caption" component="p">
              Nenhum endereço cadastrado.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrintInformation
            fullWidth
            label="Telefone"
            value={row.telefone}
            style={{
              marginLeft: "5px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PrintInformation fullWidth label="Email" value={row.email} />
        </Grid>
        <Contracts row={row} />
      </Grid>
    </Container>
  );
};
