import { Person } from "usecases/person";
import { CustomerData, CustomerResponse } from "./types";
import { Contract } from "usecases/contracts";
import { ColumnDef } from "@tanstack/react-table";
import { Action } from "components/paginated-table";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import { groupBy } from "lodash";
import RecommendIcon from "@mui/icons-material/Recommend";

export class CustomerWithContract {
  readonly contract: Contract | undefined;
  readonly customer: CustomerResponse;

  constructor({
    customer,
    contract,
  }: {
    customer: CustomerResponse;
    contract?: Contract | undefined;
  }) {
    this.contract = contract;
    this.customer = customer;
  }
}

export class CustomerPage {
  readonly people: Person[];
  readonly itemsPerPage = 5;

  constructor({ people }: { people: Person[] }) {
    this.people = people;
  }

  pages(): number {
    return Math.ceil(this.people.length / this.itemsPerPage);
  }

  static build(people: Person[], canceled: boolean): CustomerPage {
    return new CustomerPage({
      people: canceled
        ? people.filter((item) => item.people.status === 1)
        : people,
    });
  }

  columns(): ColumnDef<CustomerData>[] {
    return [
      {
        accessorKey: "nome",
        header: "Nome",
      },
      {
        accessorKey: "cpfCnpj",
        header: "Documento",
      },
      {
        accessorKey: "telefone",
        header: "Telefone",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
    ];
  }

  rows(selectedApps?: string[], selectedStated?: string[]): CustomerData[] {
    return this.filterPeopleContracts(this.getPeople(selectedStated)).map(
      (item) =>
        ({
          id: item.people.id,
          nome: item.people.nomeComercial,
          cpfCnpj:
            item.people.cpfCnpj.length === 11
              ? item.people.cpfCnpj.replace(
                  /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                )
              : item.people.cpfCnpj.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  "$1.$2.$3/$4-$5"
                ),
          telefone: item.people.informacoes?.telefone,
          email: item.people.informacoes?.email,
          contratos: item.contracts.length,
          contracts: item.contracts,
          hasInfos: !!item.people.informacoes,
          informacoes: item.people.informacoes,
        } as CustomerData)
    );
  }

  actions(
    onWhatsAppClick: (
      event: React.MouseEvent<HTMLTableCellElement> | undefined,
      data: CustomerData
    ) => any,
    onEmailClick: (
      event: React.MouseEvent<HTMLTableCellElement> | undefined,
      data: CustomerData
    ) => any,
    onMakeDealClick: (
      event: React.MouseEvent<HTMLTableCellElement> | undefined,
      data: CustomerData
    ) => any
  ): Action<CustomerData>[] {
    return [
      {
        tooltip: "Enviar Pagamentos PIX Por Whatsapp",
        color: "success",
        icon: <WhatsAppIcon fontSize="small" />,
        onClick: onWhatsAppClick,
      },
      {
        tooltip: "Enviar Email",
        color: "info",
        icon: <EmailIcon fontSize="small" />,
        onClick: onEmailClick,
      },
      {
        tooltip: "Liberar temporariamente",
        color: "warning",
        icon: <RecommendIcon fontSize="small" />,
        onClick: onMakeDealClick,
      },
    ];
  }

  filterPeopleContracts(person: Person[], selectedApps?: string[]): Person[] {
    if (!selectedApps) {
      return person;
    } else {
      return person.filter(
        (item) =>
          item.contracts.filter((contract) =>
            selectedApps.includes(contract.json.sistemaDescricao)
          ).length > 0
      );
    }
  }

  getPeople(selectedStates?: string[]): Person[] {
    if (!selectedStates) {
      return this.people;
    } else {
      return this.people.filter(
        (item) =>
          selectedStates.includes(item.people.informacoes?.uf || "") ||
          !item.people.informacoes?.uf
      );
    }
  }

  countAllContracts(selectedApps: string[], selectedStates: string[]): number {
    return this.getAllContracts(selectedApps, selectedStates).length;
  }

  countContractsPending(
    selectedApps: string[],
    selectedStates: string[]
  ): number {
    return this.getContractsPending(selectedApps, selectedStates).length;
  }

  countContractsPaid(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPaid(selectedApps, selectedStates).length;
  }

  countContractsWithBonus(
    selectedApps: string[],
    selectedStates: string[]
  ): number {
    return this.getContractsWithBonus(selectedApps, selectedStates).length;
  }

  countContractsCanceled(
    selectedApps: string[],
    selectedStates: string[]
  ): number {
    return this.getContractsCanceled(selectedApps, selectedStates).length;
  }

  getContractsCanceled(
    selectedApps: string[],
    selectedStates: string[]
  ): Contract[] {
    return this.people
      .filter(
        (item) =>
          (selectedStates.includes(item.people.informacoes?.uf || "") ||
            !item.people.informacoes?.uf) &&
          item.people.status === 1
      )
      .flatMap((item) => item.contracts)
      .filter((item) => selectedApps.includes(item.json.sistemaDescricao));
  }

  getContractsWithBonus(
    selectedApps: string[],
    selectedStates: string[]
  ): Contract[] {
    return this.people
      .filter(
        (item) =>
          selectedStates.includes(item.people.informacoes?.uf || "") ||
          !item.people.informacoes?.uf
      )
      .flatMap((item) => item.contracts)
      .filter((item) => selectedApps.includes(item.json.sistemaDescricao))
      .filter((item) => item.isBonus());
  }

  countCustomersWithBonus(
    selectedApps: string[],
    selectedStates: string[]
  ): number {
    return Object.keys(
      groupBy(
        this.getContractsWithBonus(selectedApps, selectedStates).map(
          (item) => item.json.pagadorCpfCnpj
        )
      )
    ).length;
  }

  countCustomerCanceled(
    selectedApps: string[],
    selectedStates: string[]
  ): number {
    return Object.keys(
      groupBy(
        this.getContractsCanceled(selectedApps, selectedStates).map(
          (item) => item.json.pagadorCpfCnpj
        )
      )
    ).length;
  }

  getContractsPending(
    selectedApps: string[],
    selectedStates: string[]
  ): Contract[] {
    return this.people
      .filter(
        (item) =>
          selectedStates.includes(item.people.informacoes?.uf || "") ||
          !item.people.informacoes?.uf
      )
      .flatMap((item) => item.contracts)
      .filter((item) => item.isPending())
      .filter((item) => selectedApps.includes(item.json.sistemaDescricao));
  }

  getContractsPaid(
    selectedApps: string[],
    selectedStates: string[]
  ): Contract[] {
    return this.people
      .filter(
        (item) =>
          selectedStates.includes(item.people.informacoes?.uf || "") ||
          !item.people.informacoes?.uf
      )
      .flatMap((item) => item.contracts)
      .filter((item) => item.isPaid())
      .filter((item) => selectedApps.includes(item.json.sistemaDescricao));
  }

  getAllContracts(
    selectedApps: string[],
    selectedStates: string[]
  ): Contract[] {
    return this.people
      .filter(
        (item) =>
          selectedStates.includes(item.people.informacoes?.uf || "") ||
          !item.people.informacoes?.uf
      )
      .flatMap((item) => item.contracts)
      .filter((item) => selectedApps.includes(item.json.sistemaDescricao));
  }

  getPaidAmount(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPaid(selectedApps, selectedStates).reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  getPendingAmount(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPending(selectedApps, selectedStates).reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  getBonusAmount(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsWithBonus(selectedApps, selectedStates).reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  getCanceledAmount(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsCanceled(selectedApps, selectedStates).reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  getAllAmount(selectedApps: string[], selectedStates: string[]): number {
    return this.getAllContracts(selectedApps, selectedStates).reduce(
      (previous, current) => previous + current.json.valor,
      0
    );
  }

  countCustomersPaid(selectedApps: string[], selectedStates: string[]): number {
    return Object.keys(
      groupBy(
        this.getContractsPaid(selectedApps, selectedStates).map(
          (item) => item.json.pagadorCpfCnpj
        )
      )
    ).length;
  }

  countCustomersPending(
    selectedApps: string[],
    selectedStates: string[]
  ): number {
    return Object.keys(
      groupBy(
        this.getContractsPending(selectedApps, selectedStates).map(
          (item) => item.json.pagadorCpfCnpj
        )
      )
    ).length;
  }

  countCustomers(selectedApps: string[], selectedStates: string[]): number {
    return Object.keys(
      groupBy(
        this.getAllContracts(selectedApps, selectedStates).map(
          (item) => item.json.pagadorCpfCnpj
        )
      )
    ).length;
  }

  getCostTotal(selectedApps: string[], selectedStates: string[]): number {
    return this.getAllContracts(selectedApps, selectedStates).reduce(
      (result, current) => result + current.cost(),
      0
    );
  }

  getYieldTotal(selectedApps: string[], selectedStates: string[]): number {
    return this.getAllContracts(selectedApps, selectedStates).reduce(
      (result, current) => result + current.yieldValue(),
      0
    );
  }

  gestCost(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPaid(selectedApps, selectedStates).reduce(
      (result, current) => result + current.cost(),
      0
    );
  }

  getYield(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPaid(selectedApps, selectedStates).reduce(
      (result, current) => result + current.yieldValue(),
      0
    );
  }

  getFutureCost(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPending(selectedApps, selectedStates).reduce(
      (result, current) => result + current.cost(),
      0
    );
  }

  getFutureYield(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsPending(selectedApps, selectedStates).reduce(
      (result, current) => result + current.yieldValue(),
      0
    );
  }

  getLateCost(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsWithBonus(selectedApps, selectedStates).reduce(
      (result, current) => result + current.cost(),
      0
    );
  }

  getLateYield(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsWithBonus(selectedApps, selectedStates).reduce(
      (result, current) => result + current.yieldValue(),
      0
    );
  }

  getLossCost(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsCanceled(selectedApps, selectedStates).reduce(
      (result, current) => result + current.cost(),
      0
    );
  }

  getLossYield(selectedApps: string[], selectedStates: string[]): number {
    return this.getContractsCanceled(selectedApps, selectedStates).reduce(
      (result, current) => result + current.yieldValue(),
      0
    );
  }

  getApps(): string[] {
    return Object.keys(
      groupBy(
        this.people
          .flatMap((item) => item.contracts)
          .map((item) => item.json.sistemaDescricao)
      )
    );
  }

  getStates(): string[] {
    return Object.keys(
      groupBy(
        this.people
          .flatMap((item) => item.people)
          .map((item) => item.informacoes?.uf)
          .filter((item) => item !== undefined)
      )
    ) as string[];
  }
}
