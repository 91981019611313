import { useSaurusAuthorization } from "usecases/saurusAuthorization";
import { PayContractResult, UsePayContractProps } from "./pay-contract-types";
import { useMutation } from "@tanstack/react-query";

export const usePayContract = ({ invoiceId }: UsePayContractProps) => {
  const saurus = useSaurusAuthorization();

  const retrievePaymentsFn = saurus.authorize(
    (
      authorization: string,
      { id, paymentId }: any = {
        id: null,
        paymentId: "7c9a7ba5-93b5-4532-8e5b-63337cd7b45c",
      }
    ): Promise<PayContractResult> => {
      return fetch(
        `${saurus.host}/fatura/${
          invoiceId || id
        }/pagar?formaPagamentoId=${paymentId}`,
        {
          method: "POST",
          headers: {
            Authorization: authorization,
          },
        }
      ).then((response) => response.json());
    }
  );

  const retrievePayments = useMutation<
    PayContractResult | undefined,
    Error,
    any
  >({
    mutationFn: retrievePaymentsFn,
    mutationKey: ["retrievePayment", invoiceId],
  });

  return {
    retrievePayments,
  };
};
