import DashboardIcon from "@mui/icons-material/Dashboard";
import { Menu as MenuItem } from "./types";
import { List } from "@mui/material";
import { AppMenuItem } from "./app-menu-item";

export const appMenu: MenuItem[] = [
  {
    id: "dashboard",
    display: "Dashboard",
    icon: <DashboardIcon color="primary" />,
    page: "/dashboard",
    state: {
      title: "Visualizando Contratos",
    },
  },
];

export const AppMenu = () => {
  return (
    <List>
      {appMenu.map((item) => (
        <AppMenuItem item={item} key={item.page} />
      ))}
    </List>
  );
};
