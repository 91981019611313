

export class Logger {
    private readonly isProduction: boolean;

    constructor(isProduction: boolean) {
        this.isProduction = isProduction;
    }

    info(msg: string) {
        if (this.isProduction === false) {
            console.info(msg)
        }
    }

    error(msg: string) {
        if (this.isProduction === false) {
            console.error(msg)
        }
    }
}