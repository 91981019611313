import React, { ReactNode, useCallback, useContext, useState } from "react";

const Context = {
  drawerIsOpen: false,
  mobileOpen: false,
};

interface AppContextType {
  context: typeof Context;
  setContext: React.Dispatch<React.SetStateAction<typeof Context>>;
}

const AppContext = React.createContext<AppContextType | undefined>(undefined);

export interface AppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [context, setContext] = useState(Context);

  return (
    <AppContext.Provider value={{ context, setContext }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  const handleDrawerClose = useCallback(() => {
    context?.setContext({
      drawerIsOpen: false,
      mobileOpen: false,
    });
  }, [context]);

  const handleDrawerTransitionEnd = useCallback(() => {
    context?.setContext((prev) => ({
      ...prev,
      mobileOpen: false,
    }));
  }, [context]);

  const handleDrawerToggle = useCallback(() => {
    context?.setContext((prev) => ({
      ...prev,
      drawerIsOpen: true,
    }));
  }, [context]);

  return {
    drawerIsOpen: context?.context.drawerIsOpen,
    mobileOpen: context?.context.mobileOpen,
    handleDrawerToggle,
    handleDrawerTransitionEnd,
    handleDrawerClose,
  };
};
