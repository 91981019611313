import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useEnvironment } from "usecases/environment";
import { useLogger } from "usecases/logger";
import { useLogin } from "usecases/login"


export const useSaurusAuthorization = () => {
    const auth = useLogin();
    const logger = useLogger();
    const navigate = useNavigate();
    const environment = useEnvironment();


    const host = environment.saurusTouchOneHost;

    const getAuthorization = useCallback(async () => {
        const user = await auth.user();
        return `Bearer ${user?.data.accessToken}`;
    }, [auth]);


    const authorize = <T> (
        callback: (authorization: string, params?: any) => Promise<T>
        ): (params?: any) => Promise<T | undefined> => {
            return async (params?: any) => {
                try {
                    logger.info('Try got user for saurus');
                    const user = await auth.user();
                    return await callback(`Bearer ${user?.data.accessToken}`, params)
                } catch (error: any) {
                    logger.error(`Error: ${error}`);
                    console.log('');
                    if (error.response?.status === 401) {
                        navigate('/login', {
                            preventScrollReset: true,
                            replace: false,
                        });
                    } else {
                        throw error;
                    }
                }
            }
    }



    return {
        getAuthorization,
        authorize,
        isAuthenticated: auth.isAuthenticated,
        host
    }
}