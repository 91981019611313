import { CircularProgress, Grid } from "@mui/material";

export interface LoadingProps {
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
}

export const Loading = ({ color }: LoadingProps) => {
  return (
    <Grid
      sx={{
        minHeight: "100%",
        minWidth: "100%",
      }}
      container
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color={color}></CircularProgress>
    </Grid>
  );
};
