import { createBrowserRouter } from "react-router-dom";
import { App } from "./app";
import { Login } from "./login";
import { DashboardPage } from "./dashboard";
import { ErrorHandler } from "components/error-handler";
import { CustomersPage } from "./customers";

export const pages = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorHandler />,
    children: [
      {
        path: "/dashboard",

        element: <DashboardPage />,
      },
      {
        path: "/clientes",
        element: <CustomersPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
