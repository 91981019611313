import { useEffect, useState } from "react";
import { Contract, useContracts } from "usecases/contracts";
import { Person, usePeople } from "usecases/person";
import { UseCustomersInput } from "./types";
import { CustomerPage } from "./models";

export const useCustomers = ({ fetchType, filters }: UseCustomersInput) => {
  const [data, setData] = useState<CustomerPage>(CustomerPage.build([], false));
  const people = usePeople();
  const contracts = useContracts({ filters });

  const isLoading = people.isLoading || contracts.isLoading;

  useEffect(() => {
    if (isLoading) return;

    const contractsData: Contract[] = (() => {
      switch (fetchType) {
        case "paid":
          return contracts.query.data?.getPaids() || [];
        case "pending":
          return contracts.query.data?.getPending() || [];
        case "bonus":
          return contracts.query.data?.getBonus() || [];
        case "canceled":
          return contracts.query.data?.getAll() || [];
        default:
          return contracts.query.data?.items || [];
      }
    })();

    setData(
      CustomerPage.build(
        Person.fromDifferentsResources(contractsData, people.query.data),
        fetchType === "canceled"
      )
    );
  }, [isLoading, fetchType, people.query.data, contracts.query.data]);

  return {
    isLoading,
    data,
  };
};
