import {
  Divider,
  Toolbar,
  Drawer as DrawerMUI,
  DrawerProps as DrawerMUIProps,
} from "@mui/material";
import { memo } from "react";
import { AppCustomersMenu, AppMenu } from "../app-menu";
import Logo from "images/logo.png";
import { useAppContext } from "pages/app/app-context";

export interface AppDrawerProps {
  mui: DrawerMUIProps;
  open?: boolean | undefined;
}

export const AppDrawer = memo(({ mui, open }: AppDrawerProps) => {
  const { handleDrawerTransitionEnd, handleDrawerClose, drawerIsOpen } =
    useAppContext();

  return (
    <DrawerMUI
      {...mui}
      onTransitionEnd={handleDrawerTransitionEnd}
      onClose={handleDrawerClose}
      open={open || drawerIsOpen}
    >
      <div>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "25px",
          }}
        >
          <img src={Logo} alt="Lunar Logo" loading="lazy" width="100px" />
        </Toolbar>
        <Divider />
        <AppMenu />
        <Divider />
        <AppCustomersMenu />
      </div>
    </DrawerMUI>
  );
});
