import { UseDashboardProps } from "./types";
import { DefaultDashboardFilter } from "./models";
import { useCustomers } from "usecases/customers";

export const useDashboard = (
  { filters }: UseDashboardProps = { filters: DefaultDashboardFilter }
) => {
  const customers = useCustomers({ fetchType: "all", filters });

  return {
    isLoading: customers.isLoading,
    data: customers.data,
  };
};
