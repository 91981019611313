import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useComission } from "usecases/comission";
import { useFormat } from "usecases/format";

export const useAppBar = () => {
  const location = useLocation();
  const comission = useComission({ enabled: false });
  const format = useFormat();
  const [showComission, setShowComission] = useState(false);

  const handleShowComission = useCallback(() => {
    if (showComission === false) {
      comission.refetch();
    }

    setShowComission((prev) => !prev);
  }, [comission, showComission]);

  const comissionValue = useMemo(
    () =>
      format.toCurrencyFormat(comission.data?.somatorios?.ValorComissao || 0),
    [format, comission.data?.somatorios?.ValorComissao]
  );

  return {
    location,
    comission,
    showComission,
    comissionValue,
    handleShowComission,
  };
};
