import { Logger, useLogger } from "usecases/logger";
import { addSeconds, isAfter } from "date-fns";

export interface Data<T = any> {
    life: number;
    date: Date;
    data: T;
}

class Storage {
    private readonly logger: Logger;

    constructor(logger: Logger) {
        this.logger = logger;
    }


    private build(data: any, life: number): Data {
        return {
            life,
            data,
            date: new Date(),
        }
    }

    private message(msg: string, key: string) {
        return `[${key}]: ${msg} ------ at: ${new Date()}`
    }


    async save<T>(input: T, key: string, life: number): Promise<T> {
        const model = this.build(input, life)

        this.logger.info(this.message(`Saving new object: ${JSON.stringify(model)}`, key))

        const str = btoa(JSON.stringify(model))

        this.logger.info(this.message(`Saving new object: ${JSON.stringify(model)}`, key))

        localStorage.setItem(key, str)

        return input;
    }

    async get<T>(key: string, validate: boolean = false): Promise<Data<T> | undefined> {
        const obj = localStorage.getItem(key)

        if (!obj) {
            return undefined;
        }

        const model = JSON.parse(atob(obj)) as Data<T>

        if (!model) {
            return undefined;
        }

        if (isAfter(new Date(), addSeconds(model.date, model.life)) && validate) {
            return undefined;
        }

        return model as Data<T>
    }
}


export const useStorage = (): Storage => {
    const logger = useLogger()
    const storage = new Storage(logger)

    return storage
}