import {
  AppBar as MuiAppBar,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppContext } from "pages/app/app-context";
import { useAppBar } from "./use-app-bar";
import { Fragment } from "react/jsx-runtime";

export interface AppBarProps {
  drawerWidth: number;
}

export const AppBar = ({ drawerWidth }: AppBarProps) => {
  const {
    location,
    handleShowComission,
    comission,
    showComission,
    comissionValue,
  } = useAppBar();

  const { handleDrawerToggle } = useAppContext();

  return (
    <MuiAppBar
      position="fixed"
      elevation={0}
      sx={(theme) => ({
        background: theme.palette.primary.dark,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      })}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <IconButton
          color="inherit"
          aria-label="abrir menu"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" fontWeight="bold" noWrap component="div">
          {location.state?.title || "Visualizar"}
        </Typography>
        <IconButton
          color="inherit"
          aria-label="visualizar comissão"
          edge="end"
          onClick={handleShowComission}
        >
          {showComission ? (
            comission.isLoading ? (
              <CircularProgress
                sx={{ width: "20px", color: "#FFF" }}
                size="small"
              />
            ) : (
              <Fragment>
                <Typography
                  component="p"
                  variant="subtitle2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <b>{comissionValue}</b>
                </Typography>
              </Fragment>
            )
          ) : (
            <VisibilityIcon fontSize="small" />
          )}
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};
