import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useSaurusAuthorization } from "usecases/saurusAuthorization";

export const useAgreement = () => {
  const saurus = useSaurusAuthorization();
  const makeDealFn = saurus.authorize(
    (authorization: string, personId: string): Promise<void> => {
      const options = {
        method: "POST",
        headers: {
          accept: "*/*",
          Authorization: authorization,
        },
      };

      return fetch(
        `https://api-licenciamento.touchone.com.br/api/v2/Pessoa/${personId}/liberacao-provisoria`,
        options
      )
        .then((response) => {
          if (response.status === 200) {
            toast(`Liberação efetuada com sucesso!!!`, {
              type: "success",
            });
          }
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            toast(
              `Não foi possível realizar a liberação, você deve esperar ao menos 30 dias para fazer outra liberação`,
              {
                type: "error",
              }
            );
          } else {
            toast(
              `Não foi possível realizar a liberação, tente novamente mais tarde.`,
              {
                type: "warning",
              }
            );
          }
        });
    }
  );

  const makeDeal = useMutation({
    mutationFn: makeDealFn,
  });

  return {
    makeDeal,
  };
};
