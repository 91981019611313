import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogin } from "usecases/login";
import { Menu } from "./components";
import { useAppContext } from "./app-context";

export const useApp = () => {
  const login = useLogin();
  const location = useLocation();
  const navigation = useNavigate();
  const { handleDrawerClose } = useAppContext();

  const goTo = useCallback(
    (to: string, state: string) => {
      navigation(to, {
        state,
      });
      handleDrawerClose();
    },
    [navigation, handleDrawerClose]
  );

  const navigateOnMenu = useCallback(
    ({ page, state }: Menu) => {
      navigation(page, {
        state,
      });
      handleDrawerClose();
    },
    [navigation, handleDrawerClose]
  );

  useEffect(() => {
    async function redirectOrPutUser() {
      const authenticated = await login.isAuthenticated();

      if (authenticated !== true) {
        navigation("/login");
      }
    }

    redirectOrPutUser();
  }, [navigation, login]);

  return {
    login,
    location,
    goTo,
    navigateOnMenu,
  };
};
