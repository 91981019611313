import { Box, Grid, Slide } from "@mui/material";
import { Outlet } from "react-router-dom";
import { AppDrawer, AppBar } from "./components";
import { AppContextProvider } from "./app-context";

const drawerWidth = 240;

export const App = () => {
  return (
    <Slide in={true} direction="down">
      <div
        style={{
          height: "100%",
          width: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        <AppContextProvider>
          <AppBar drawerWidth={drawerWidth} />
          <div
            style={{
              height: "calc(100% - 56px)",
            }}
          >
            <Box
              component="nav"
              sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
                display: { xs: "none", sm: "block" },
              }}
            >
              <AppDrawer
                mui={{
                  variant: "temporary",
                  ModalProps: {
                    keepMounted: true,
                  },
                  sx: {
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  },
                }}
              />
              <AppDrawer
                mui={{
                  variant: "permanent",
                  sx: {
                    display: { xs: "none", sm: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  },
                }}
                open
              />
            </Box>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 2,
                width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
                marginLeft: { sm: "240px", xs: "0" },
                height: "100%",
                paddingTop: "90px",
              }}
            >
              <Grid
                container
                padding={0}
                margin={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Outlet></Outlet>
              </Grid>
            </Box>
          </div>
        </AppContextProvider>
      </div>
    </Slide>
  );
};
