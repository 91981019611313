import { Button, Grid, Typography } from "@mui/material";
import React from "react";

export interface ErrorHandlerProps {
  children?: React.ReactNode;
}

interface ErrorHandlerState {
  hasError: boolean;
  error?: Error | undefined;
}

export class ErrorHandler extends React.Component<
  ErrorHandlerProps,
  ErrorHandlerState
> {
  constructor(props: ErrorHandlerProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error);
    console.error(errorInfo);
    this.setState({
      hasError: true,
      error: error,
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            sx={{
              display: "inline-block",
            }}
          >
            <Typography variant="h5" gutterBottom>
              <b>Ops, algo inesperado aconteceu!</b>
            </Typography>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Detalhe: {this.state.error?.message}
              </Typography>
            </div>
            <Button
              variant="contained"
              onClick={() => {
                this.setState({
                  hasError: !this.state.hasError,
                  error: undefined,
                });
              }}
            >
              Atualizar
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      return this.props.children;
    }
  }
}
