import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Menu } from "../types";
import { useCallback } from "react";
import { useApp } from "../../../use-app";

export interface AppMenuItemProps {
  item: Menu;
}

export const AppMenuItem = ({ item }: AppMenuItemProps) => {
  const app = useApp();
  const handleClick = useCallback(() => app.navigateOnMenu(item), [item, app]);

  return (
    <ListItem key={item.id} disablePadding>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={<b>{item.display}</b>} />
      </ListItemButton>
    </ListItem>
  );
};
