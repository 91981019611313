export function parsePhone(
  text: string | undefined,
  prefix: string = "+55"
): string {
  const value = (text || "")
    .replace(/\D+/g, "")
    .trim()
    .replace(" ", "")
    .replace(")", "")
    .replace("(", "")
    .replace("-", "");

  return prefix ? `${prefix}${value}` : value;
}
