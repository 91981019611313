import { useCallback, useState } from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import type { ColumnDef, Row } from "@tanstack/react-table";
import { useReactTable } from "@tanstack/react-table";
import { Table, TableContainer, TablePagination } from "@mui/material";
import React from "react";
import { Columns } from "./columns";
import { Rows, ViewDetailsComponent } from "./rows";
import { Filter } from "./filter";

export interface Action<T> {
  tooltip: string;
  onClick?: (
    event: React.MouseEvent<HTMLTableCellElement> | undefined,
    data: T
  ) => any;
  icon: JSX.Element;
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
}

export type PaginatedTableProps<TData> = {
  columns: ColumnDef<TData>[];
  actions?: Action<TData>[];
  rows: TData[];
  details: ViewDetailsComponent<TData>;
  minHeight: number | string;
  maxHeight: number | string;
};

export function PaginatedTable<TData>({
  columns,
  rows,
  minHeight,
  maxHeight,
  actions,
  details,
}: PaginatedTableProps<TData>): JSX.Element {
  const [globalFilter, setGlobalFilter] = useState("");

  const globalFilterFn = useCallback(
    (row: Row<TData>, columnId: string, filterValue: any) => {
      const value = row.getValue(columnId);
      return String(value)
        .toLowerCase()
        .includes(String(filterValue).toLowerCase());
    },
    []
  );

  const table = useReactTable<TData>({
    columns: columns,
    data: rows,
    state: {
      globalFilter,
    },
    globalFilterFn: globalFilterFn,
    onGlobalFilterChange: setGlobalFilter,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    table.setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    table.setPageSize(Number(event.target.value));
  };

  return (
    <div
      style={{
        overflow: "scroll",
        textAlign: "end",
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        background: "#FFF",
      }}
    >
      <Filter
        table={table}
        filter={globalFilter}
        onFilterChange={setGlobalFilter}
      />
      <TableContainer
        sx={{
          minHeight,
          maxHeight,
          background: "transparent",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <Columns table={table} actions={actions}></Columns>
          <Rows actions={actions} table={table} details={details}></Rows>
        </Table>
        <TablePagination
          sx={{
            position: "sticky",
            zIndex: 1,
            bottom: 0,
            backgroundColor: "#FFF",
          }}
          rowsPerPageOptions={[10, 15, 100]}
          component="div"
          count={table.getFilteredRowModel().rows.length}
          rowsPerPage={table.getState().pagination.pageSize}
          page={table.getState().pagination.pageIndex}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}
