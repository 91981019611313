import { InputBase, alpha, styled } from "@mui/material";
import { Table } from "@tanstack/react-table";
import React, { useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";

export interface FilterProps<T> {
  table: Table<T>;
  filter?: string | undefined;
  onFilterChange?: (value: string) => any | undefined;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  borderBottom: "2px solid rgb(239, 239, 239)",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const Filter = <T,>({
  table,
  filter,
  onFilterChange,
}: FilterProps<T>) => {
  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!!onFilterChange) {
        onFilterChange(event.target.value);
      }
    },
    [onFilterChange]
  );

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon sx={{ color: "#CCC" }} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Pesquisar ..."
        inputProps={{ "aria-label": "pesquisar" }}
        value={filter || ""}
        onChange={handleSearchChange}
      />
    </Search>
  );
};
