import { SaurusPaginationResponse } from "common";
import { useEnvironment } from "usecases/environment";
import { useLogger } from "usecases/logger";
import { useSaurusAuthorization } from "usecases/saurusAuthorization";
import { Comission, UseComissionProps } from "./types";
import { useQuery } from "@tanstack/react-query";

export const DefaultComission: SaurusPaginationResponse<Comission> = {
  list: [],
  pageIndex: 0,
  pageSize: 0,
  totalPages: 0,
  totalResults: 0,
  somatorios: {
    Encargo: 0,
    MultaJuros: 0,
    Valor: 0,
    ValorCusto: 0,
    ValorCustoOriginal: 0,
    ValorPago: 0,
    ValorBaseCalculo: 0,
    ValorComissao: 0,
  },
};

export const useComission = ({ enabled }: UseComissionProps) => {
  const logger = useLogger();
  const environment = useEnvironment();
  const saurus = useSaurusAuthorization();

  const retrieve = saurus.authorize(
    async (
      authorization: string
    ): Promise<SaurusPaginationResponse<Comission>> => {
      const options = {
        method: "GET",
        headers: {
          Authorization: authorization,
        },
      };

      logger.info(`Query: Get Comission`);

      return fetch(
        `${environment.saurusTouchOneHost}/comissao/pendentes`,
        options
      )
        .then((result) => result.json())
        .catch(() => DefaultComission);
    }
  );

  const comission = useQuery({
    queryKey: ["comission"],
    queryFn: retrieve,
    enabled,
  });

  return {
    isLoading:
      comission.isFetching || comission.isLoading || comission.isRefetching,
    data: comission.data,
    refetch: comission.refetch,
  };
};
