import { Grid, List, Typography } from "@mui/material";
import { ContractItem } from "../contract-item";
import { CustomerData } from "usecases/customers";

export interface ContractsProps {
  row: CustomerData;
}

export const Contracts = ({ row }: ContractsProps) => {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        <b>Contratos:</b>
      </Typography>
      <List>
        {row.contracts.map((contract, index) => (
          <ContractItem
            key={contract.json.id}
            contract={contract}
            index={index}
            phoneNumber={row.telefone}
          />
        ))}
      </List>
    </Grid>
  );
};
