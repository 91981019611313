import { useMutation } from "@tanstack/react-query";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { useEnvironment } from "usecases/environment";
import { useLogger } from "usecases/logger";
import { Data, useStorage } from "usecases/storage";

export interface LoginInput {
  login: string;
  senha: string;
}

export interface LoginUserToken {
  id: string;
  nome: string;
}

export interface LoginOutput {
  accessToken: string;
  expiresIn: number;
  usuarioToken: LoginUserToken;
}

export const useLogin = () => {
  const keyName = "login";
  const key = [keyName];
  const environment = useEnvironment();
  const logger = useLogger();
  const storage = useStorage();

  const isAuthenticated = async (): Promise<Boolean> => {
    const auth = await storage.get(keyName, true);
    return !!auth;
  };

  const user = (): Promise<Data<LoginOutput> | undefined> => {
    return storage.get<LoginOutput>(keyName, false);
  };

  const execute = async (input: LoginInput): Promise<LoginOutput> => {
    logger.info(`Submit Input: ${input}`);

    const options = {
      method: "POST",
      headers: {
        cookie: "acaAffinity=%22d691e9478882c062%22",
        accept: "text/plain",
        "Content-Type": "application/json-patch+json",
      },
      body: JSON.stringify(input),
    };

    const result = await fetch(
      `${environment.saurusTouchOneHost}/identidade/autenticar`,
      options
    );

    if (result.ok !== true) {
      throw new Error("Login ou senha incorretos!");
    }

    const response = (await result.json()) as LoginOutput;

    storage.save(response, keyName, response.expiresIn);

    return response;
  };

  const errorHandler = (error: Error) => {
    toast("Usuário ou senha incorretos!", {
      type: "error",
    });
  };

  const mutation = useMutation({
    mutationFn: execute,
    mutationKey: key,
    onError: errorHandler,
  });

  const submit = async (
    input: LoginInput,
    helpers: FormikHelpers<LoginInput>
  ) => {
    try {
      await mutation.mutateAsync(input);
    } catch (error) {
      await helpers.setFieldValue("login", "");
      await helpers.setFieldValue("senha", "");
      helpers.resetForm();
      throw error;
    }
  };

  return {
    key,
    isAuthenticated,
    user,
    execute,
    errorHandler,
    mutation,
    submit,
  };
};
