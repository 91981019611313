import { useQuery } from "@tanstack/react-query";
import { fetchAllPagesData } from "common/fetch-all-pages";
import { SaurusPaginationResponse } from "common/saurus-pagination";
import { useNavigate } from "react-router-dom";
import { Contract } from "usecases/contracts";
import { useLogger } from "usecases/logger";
import { useSaurusAuthorization } from "usecases/saurusAuthorization";

export interface PersonResponse {
  id: string;
  tipo: number;
  nomeComercial: string;
  recebeIndicacoes: number;
  responsavel: string;
  safraId: any;
  cpfCnpj: string;
  status: number;
  revendaId: string;
  revendaNome: string;
  informacoes?: PersonInfosResponse;
  gerenteId: any;
  vendedorId: any;
  gerenteNome: any;
  vendedorNome: any;
  classificacaoId: any;
  classificacaoAbreviacao: any;
  dataCadastro: string;
}

export interface PersonInfosResponse {
  id: string;
  pessoaId: string;
  xNome: string;
  email: string;
  telefone: string;
  informacao: string;
  fantasia: string;
  logradouro: string;
  cep: string;
  numero: string;
  bairro: string;
  municipio: string;
  codMunicipio: number;
  uf: string;
  complemento: string;
  isComercial: boolean;
  isJuridico: boolean;
  isFinanceiro: boolean;
  latitude: number;
  longitude: number;
  ie: string;
  im: any;
  cnae?: string;
  crt: number;
  segmentoId?: string;
  segmento: any;
  obs?: string;
  dataAberturaEmpresa: any;
}

export class Person {
  readonly contracts: Contract[];
  readonly people: PersonResponse;

  constructor({
    contracts,
    people,
  }: {
    people: PersonResponse;
    contracts: Contract[];
  }) {
    this.contracts = contracts;
    this.people = people;
  }

  static fromDifferentsResources(
    contracts: Contract[],
    people: PersonResponse[],
    result: Person[] = []
  ): Person[] {
    for (const contract of contracts) {
      const resultSearch = result.find(
        (item) => item.people.cpfCnpj === contract.json.pagadorCpfCnpj
      );
      const peopleSearch = people.find(
        (item) => item.cpfCnpj === contract.json.pagadorCpfCnpj
      );

      if (!resultSearch) {
        if (!peopleSearch) {
          continue;
        }

        result.push(
          new Person({ contracts: [contract], people: peopleSearch })
        );
      } else {
        result.map((item) => {
          if (item.people.id === peopleSearch?.id) {
            item.contracts.push(contract);
            return item;
          } else {
            return item;
          }
        });
      }
    }

    return result;
  }
}

export const usePeople = () => {
  const logger = useLogger();
  const saurus = useSaurusAuthorization();
  const navigate = useNavigate();

  const retrieve = saurus.authorize(
    async (authorization: string): Promise<any> => {
      const options = {
        method: "GET",
        headers: {
          Authorization: authorization,
        },
      };

      const page = 1;
      const pageSize = 100;

      const query = {
        page: page.toString(),
        pageSize: pageSize.toString(),
      };

      logger.info(`Query: ${new URLSearchParams(query).toString()}`);

      try {
        const persons = await fetchAllPagesData<
          PersonResponse,
          SaurusPaginationResponse<PersonResponse>
        >(
          `${saurus.host}/Pessoa?${new URLSearchParams(query).toString()}`,
          options
        );

        return persons.flatMap((item) => item.list);
      } catch (e) {
        navigate("/");
      }
    }
  );

  const query = useQuery({
    queryKey: ["people"],
    queryFn: retrieve,
    initialData: [],
  });

  const isLoading = query.isLoading || query.isFetching;

  return {
    query,
    isLoading,
  };
};
