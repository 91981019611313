import { appendPageParams } from "./append-params";
import { SaurusPaginationResponse } from "./saurus-pagination";

export const fetchAllPagesData = async <M, T extends SaurusPaginationResponse<M>> (
    url: string, options: RequestInit = {}
): Promise<SaurusPaginationResponse<T>[]> => {
    let allData: SaurusPaginationResponse<T>[] = [];
    let currentPage = 1;
    let totalPages = 1;

  
    while (currentPage <= totalPages) {
      const response = await fetch(appendPageParams(url, currentPage), options);

      if (!response.ok) {
        throw response;
      }

      const data: SaurusPaginationResponse<T> = await response.json();
      allData.push(data);
      totalPages = data.totalPages;
      currentPage++;
    }
  
    return allData;
  };