import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Table, flexRender } from "@tanstack/react-table";
import { Fragment } from "react";
import { Action } from "./paginated-table";

export interface ColumsProps<Data> {
  table: Table<Data>;
  actions?: Action<Data>[];
}

export const Columns = <Data,>({ table, actions }: ColumsProps<Data>) => {
  return (
    <TableHead>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow
          sx={{
            "@media (max-width: 800px)": {
              display: "none",
            },
          }}
          key={headerGroup.id}
        >
          <Fragment>
            <TableCell></TableCell>
            {headerGroup.headers.map((header) => (
              <TableCell key={header.id}>
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <TableSortLabel
                      active={!!header.column.getIsSorted()}
                      direction={
                        header.column.getIsSorted() === "desc" ? "desc" : "asc"
                      }
                    />
                  </div>
                )}
              </TableCell>
            ))}
          </Fragment>
        </TableRow>
      ))}
    </TableHead>
  );
};
