import { Grid, Slide } from "@mui/material";
import { Loading } from "components/loading";
import { PaginatedTable } from "components/paginated-table";
import { CustomerPage } from "components/customer-page";
import { useCustomersPage } from "./use-customers-page";

export const CustomersPage = () => {
  const {
    isLoading,
    onWhatsAppClick,
    sendEmail,
    data,
    selectedApps,
    seletedStates,
    handleMakeDeal,
  } = useCustomersPage();

  if (isLoading) {
    return (
      <Grid container alignItems="center" justifyContent="center" height="100%">
        <Loading />
      </Grid>
    );
  }

  return (
    <Slide in={true} direction="right">
      <div
        style={{
          width: "100%",
        }}
      >
        <PaginatedTable
          columns={data.columns()}
          rows={data.rows(selectedApps, seletedStates)}
          actions={data.actions(onWhatsAppClick, sendEmail, handleMakeDeal)}
          details={CustomerPage}
          maxHeight="600px"
          minHeight="600px"
        />
      </div>
    </Slide>
  );
};
