import { List, Typography } from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PaidIcon from "@mui/icons-material/Paid";
import ListIcon from "@mui/icons-material/List";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AppMenuItem } from "./app-menu-item";
import { Menu } from "./types";

export const menu: Menu[] = [
  {
    icon: <ListIcon color="info" />,
    display: "Todos",
    id: "all",
    page: "/clientes",
    state: {
      title: "Todos os clientes",
      fetchType: "all",
    },
  },
  {
    icon: <PaidIcon color="success" />,
    display: "Pagos",
    id: "paid",
    page: "/clientes",
    state: {
      title: "Clientes em dia",
      fetchType: "paid",
    },
  },
  {
    icon: <AccessTimeIcon color="warning" />,
    display: "Pendentes",
    id: "late",
    page: "/clientes",
    state: {
      title: "Clientes com pagamento pendente",
      fetchType: "pending",
    },
  },
  {
    icon: <PersonRemoveIcon color="error" />,
    display: "Bonificados",
    id: "bonus",
    page: "/clientes",
    state: {
      title: "Clientes bonificados",
      fetchType: "bonus",
    },
  },
  {
    icon: <CancelPresentationIcon color="inherit" />,
    display: "Cancelados",
    id: "canceled",
    page: "/clientes",
    state: {
      title: "Clientes cancelados",
      fetchType: "canceled",
    },
  },
];

export const AppCustomersMenu = () => {
  return (
    <List>
      <Typography
        sx={(theme) => ({
          padding: "5px",
        })}
        variant="subtitle1"
        gutterBottom
      >
        <b>Clientes:</b>
      </Typography>
      {menu.map((item) => (
        <AppMenuItem item={item} key={item.id} />
      ))}
    </List>
  );
};
