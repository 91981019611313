
function appendPageParams(baseUrl: string, page: number): string {
    const urlObj = new URL(baseUrl);
    urlObj.searchParams.set('page', page.toString());
    urlObj.searchParams.set('pageSize', '100');
    return urlObj.toString();
};

function appendQuery(baseUrl: string, query: any = {}): string {
    const urlObj = new URL(baseUrl);

    return Object
    .keys(query)
    .reduce((previous, key) => {
        previous.searchParams.set(key, query[key].toString()); 
        return previous;
    }, urlObj)
    .toString();
}


export {
    appendPageParams,
    appendQuery
}