import { All, Environment } from "./types";

export const all: All = {
  isProduction: process.env.NODE_ENV === "production",
};

export const development: Environment = {
  saurusTouchOneHost: "https://api-licenciamento.touchone.com.br/api/v2",
};

export const production: Environment = {
  saurusTouchOneHost: "https://api-licenciamento.touchone.com.br/api/v2",
};

export const environments: {
  all: All;
  development: Environment;
  production: Environment;
  test: Environment;
} = {
  all,
  development,
  production,
  test: development,
};
