import { ListItem, ListItemText, Typography, useTheme } from "@mui/material";
import { PrintInformation } from "components/print-information";
import { format } from "date-fns";
import { useCallback } from "react";
import { Contract } from "usecases/contracts";
import { usePayContract } from "usecases/pay-contract";

export interface ContractItemProps {
  contract: Contract;
  phoneNumber: string | undefined;
  index: number;
}

export const ContractItem = ({
  contract,
  phoneNumber,
  index,
}: ContractItemProps) => {
  const theme = useTheme();

  const payment = usePayContract({
    invoiceId: contract.json.id,
  });

  const refreshPix = useCallback(async () => {
    const result = await payment.retrievePayments.mutateAsync({
      id: null,
      paymentId: "7c9a7ba5-93b5-4532-8e5b-63337cd7b45c",
    });
    return result?.pixCopiaCola || "";
  }, [payment]);

  const refreshInvoice = useCallback(async () => {
    const result = await payment.retrievePayments.mutateAsync({
      id: null,
      paymentId: "8b07de99-2e5c-43a8-b26a-cd3f3bb4c66a",
    });

    return result?.url || "";
  }, [payment]);

  return (
    <ListItem
      key={contract.json.id}
      sx={{
        background:
          index % 2 === 0
            ? `rgb(235, 235, 235)`
            : theme.palette.background.paper,
        width: "100%",
      }}
      alignItems="flex-start"
    >
      <ListItemText
        primary={
          <Typography
            sx={{ display: "inline" }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            <b
              style={{
                color: theme.palette.primary.main,
              }}
            >
              {contract.json.sistemaDescricao}
            </b>
            <b>
              <i>
                {` `}Vencimento:{` `}
              </i>
            </b>
            {format(contract.json.dataVencimento, "dd/MM/yyyy")}
            <b>
              <i>
                {` `}Valor R${` `}
              </i>
            </b>
            {contract.json.valor}{" "}
            <b>
              <i>
                {` `}Pago:{` `}
              </i>
            </b>
            {!!contract.json.dataPagamento ? "Sim" : "Não"}
          </Typography>
        }
        secondary={
          <div
            style={{
              margin: "10px 0",
            }}
          >
            <PrintInformation
              fullWidth
              label="Boleto"
              link
              whatsapp={phoneNumber}
              refresh={refreshInvoice}
              value={contract.json.boletoUrl}
            />
            <PrintInformation
              fullWidth
              label="Código PIX"
              whatsapp={phoneNumber}
              refresh={refreshPix}
              value={contract.json.pixCodigo || "Clique ao lado para gerar!"}
            />
          </div>
        }
      />
    </ListItem>
  );
};
