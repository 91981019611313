import {
  Checkbox,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Slide,
  Typography,
} from "@mui/material";
import { Summary } from "components/summary";
import { Loading } from "components/loading";
import { useDashboardPage } from "./use-dashboard-page";
import { DateRangePicker } from "@adobe/react-spectrum";
import { defaultTheme, Provider } from "@adobe/react-spectrum";

export const DashboardPage = () => {
  const {
    data,
    navigateToAll,
    navigateToPaids,
    navigateToPending,
    navigateToBonus,
    navigateToCanceled,
    filters,
    handleFilterChange,
    selectedApps,
    handleSelectedAppChange,
    selectedStates,
    handleSelectedStateChange,
    isLoading,
  } = useDashboardPage();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Slide in={true} direction="right" unmountOnExit>
      <Grid container spacing={2} flexDirection="column">
        <Grid item xs={12}>
          <Provider theme={defaultTheme} colorScheme="light">
            <Paper
              variant="outlined"
              sx={(theme) => ({
                padding: "20px",
                background: `rgb(245, 245, 245)`,
                borderColor: "#CCC",
              })}
            >
              <Typography variant="h6" fontWeight="bold">
                Aplicar Filtros
              </Typography>
              <DateRangePicker
                label="Período"
                value={filters}
                onChange={handleFilterChange}
                width="100%"
              />
            </Paper>
          </Provider>
        </Grid>
        <Grid item xs={12}>
          <Paper
            variant="outlined"
            sx={(theme) => ({
              padding: "20px",
              background: `rgb(245, 245, 245)`,
              borderColor: "#CCC",
            })}
          >
            <Typography variant="h6" fontWeight="bold">
              Visualizar somente:
            </Typography>
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                margin: 0,
                marginTop: "10px",
              }}
            >
              <InputLabel id="apps-label">Sistemas</InputLabel>
              <Select
                labelId="pps-label"
                id="apps-select"
                multiple
                value={selectedApps}
                onChange={handleSelectedAppChange}
                input={
                  <FilledInput
                    sx={{
                      background: "rgb(245, 245, 245)",
                    }}
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {data.getApps().map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedApps.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{
                m: 1,
                width: "100%",
                margin: 0,
                marginTop: "10px",
              }}
            >
              <InputLabel id="apps-label">Estados</InputLabel>
              <Select
                labelId="pps-label"
                id="apps-select"
                multiple
                value={selectedStates}
                onChange={handleSelectedStateChange}
                input={
                  <FilledInput
                    sx={{
                      background: "rgb(245, 245, 245)",
                    }}
                  />
                }
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {data.getStates().map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedStates.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Grid item sm={12} md={4} width="100%">
              <Summary
                category="primary"
                info="Todos"
                onClick={navigateToAll}
                yieldLabel="Lucro mensal"
                yieldValue={data.getYieldTotal(selectedApps, selectedStates)}
                cost={data.getCostTotal(selectedApps, selectedStates)}
                count={data.countAllContracts(selectedApps, selectedStates)}
                amount={data.getAllAmount(selectedApps, selectedStates)}
                customers={data.countCustomers(selectedApps, selectedStates)}
              />
            </Grid>

            <Grid item sm={12} md={4} width="100%">
              <Summary
                category="success"
                info="Pagos"
                yieldLabel="Lucro"
                onClick={navigateToPaids}
                yieldValue={data.getYield(selectedApps, selectedStates)}
                cost={data.gestCost(selectedApps, selectedStates)}
                count={data.countContractsPaid(selectedApps, selectedStates)}
                amount={data.getPaidAmount(selectedApps, selectedStates)}
                customers={data.countCustomersPaid(
                  selectedApps,
                  selectedStates
                )}
              />
            </Grid>
            <Grid item sm={12} md={4} width="100%">
              <Summary
                category="warning"
                info="Pendentes"
                onClick={navigateToPending}
                yieldValue={data.getFutureYield(selectedApps, selectedStates)}
                cost={data.getFutureCost(selectedApps, selectedStates)}
                count={data.countContractsPending(selectedApps, selectedStates)}
                amount={data.getPendingAmount(selectedApps, selectedStates)}
                yieldLabel="Previsto"
                customers={data.countCustomersPending(
                  selectedApps,
                  selectedStates
                )}
              />
            </Grid>
            <Grid item sm={12} md={4} width="100%">
              <Summary
                category="info"
                info="Bonificados"
                onClick={navigateToBonus}
                yieldValue={data.getLateYield(selectedApps, selectedStates)}
                cost={data.getLateCost(selectedApps, selectedStates)}
                count={data.countContractsWithBonus(
                  selectedApps,
                  selectedStates
                )}
                yieldLabel="Bonificados neste período"
                amount={data.getBonusAmount(selectedApps, selectedStates)}
                customers={data.countCustomersWithBonus(
                  selectedApps,
                  selectedStates
                )}
              />
            </Grid>
            <Grid item sm={12} md={4} width="100%">
              <Summary
                category="dark"
                info="Cancelados"
                yieldLabel="Prejuizo"
                yieldValue={data.getLossYield(selectedApps, selectedStates)}
                cost={data.getLossCost(selectedApps, selectedStates)}
                onClick={navigateToCanceled}
                count={data.countContractsCanceled(
                  selectedApps,
                  selectedStates
                )}
                amount={data.getCanceledAmount(selectedApps, selectedStates)}
                customers={data.countCustomerCanceled(
                  selectedApps,
                  selectedStates
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};
