import { useCallback } from "react";

export const useFormat = () => {
  const toCurrencyFormat = useCallback((number: number) => {
    return number.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }, []);

  return {
    toCurrencyFormat,
  };
};
