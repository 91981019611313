import { DateFilter } from "common";
import { parseDate } from "@internationalized/date";
import { endOfMonth, startOfMonth, subDays } from "date-fns";

export const DefaultDashboardFilter: DateFilter = {
  start: parseDate(startOfMonth(new Date()).toISOString().split(`T`)[0]),
  end: parseDate(
    subDays(endOfMonth(new Date()), 1).toISOString().split(`T`)[0]
  ),
};
