import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { DefaultDashboardFilter, useDashboard } from "usecases/dashboard";

export const useDashboardPage = () => {
  const [filters, handleFilterChange] = useState(DefaultDashboardFilter);
  const dashboard = useDashboard({
    filters,
  });
  const isLoading = dashboard.isLoading;
  const data = dashboard.data;
  const [selectedApps, setSelectedApp] = useState<string[]>([]);
  const [selectedStates, setSelectedState] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboard.isLoading === false) {
      setSelectedApp(dashboard.data.getApps());
      setSelectedState(dashboard.data.getStates());
    }
  }, [dashboard.isLoading, dashboard.data]);

  const navigateToPaids = useCallback(() => {
    navigate("/clientes", {
      state: {
        title: "Clientes em dia",
        fetchType: "paid",
        selectedApps,
        selectedStates,
        filters,
      },
    });
  }, [navigate, selectedApps, selectedStates, filters]);

  const navigateToPending = useCallback(() => {
    navigate("/clientes", {
      state: {
        title: "Clientes com pagamento pendente",
        fetchType: "pending",
        selectedApps,
        selectedStates,
        filters,
      },
    });
  }, [navigate, selectedApps, selectedStates, filters]);

  const navigateToAll = useCallback(() => {
    navigate("/clientes", {
      state: {
        title: "Visualizando todos os clientes",
        fetchType: "all",
        selectedApps,
        selectedStates,
        filters,
      },
    });
  }, [navigate, selectedApps, selectedStates, filters]);

  const navigateToBonus = useCallback(() => {
    navigate("/clientes", {
      state: {
        title: "Visualizando clientes bonificados",
        fetchType: "bonus",
        selectedApps,
        selectedStates,
        filters,
      },
    });
  }, [navigate, selectedApps, selectedStates, filters]);

  const navigateToCanceled = useCallback(() => {
    navigate("/clientes", {
      state: {
        title: "Visualizando clientes cancelados",
        fetchType: "canceled",
        selectedApps,
        selectedStates,
        filters,
      },
    });
  }, [navigate, selectedApps, selectedStates, filters]);

  const handleSelectedAppChange = useCallback(
    (event: SelectChangeEvent<typeof selectedApps>) => {
      const {
        target: { value },
      } = event;
      setSelectedApp(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    },
    []
  );

  const handleSelectedStateChange = useCallback(
    (event: SelectChangeEvent<typeof selectedStates>) => {
      const {
        target: { value },
      } = event;

      setSelectedState(typeof value === "string" ? value.split(",") : value);
    },
    []
  );

  return {
    navigateToAll,
    navigateToPending,
    navigateToPaids,
    navigateToBonus,
    navigateToCanceled,
    filters,
    data,
    handleFilterChange,
    selectedApps,
    handleSelectedAppChange,
    handleSelectedStateChange,
    selectedStates,
    isLoading,
  };
};
