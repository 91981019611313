import { PaletteColorOptions, PaletteColor, createTheme } from "@mui/material";
import { ptBR } from "@mui/material/locale";

declare module "@mui/material/styles" {
  interface Palette {
    dark: PaletteColor;
  }
  interface PaletteOptions {
    dark?: PaletteColorOptions;
  }
}

declare module "@mui/material/Alert" {
  interface AlertPropsColorOverrides {
    primary: true;
    dark: true;
  }

  interface AlertPropsSeverityOverrides {
    primary: true;
    dark: true;
  }
}

export const theme = createTheme(
  {
    typography: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        dark: "#4f1964",
        main: "#95308c",
        light: "#b569ad",
      },
      secondary: {
        dark: "#641954",
        main: "#c71b7a",
        light: "#cf60a3",
      },
      info: {
        dark: "#291964",
        main: "#4c459b",
        light: "#7d7db8",
      },
      success: {
        dark: "#2e6419",
        main: "#86bd44",
        light: "#aad07d",
      },
      warning: {
        dark: "#644f19",
        main: "#baa829",
        light: "#cebf63",
      },
      error: {
        dark: "#64192e",
        main: "#bd303c",
        light: "#d35e6e",
      },
      dark: {
        dark: `#000`,
        main: "#999",
        contrastText: "#FFF",
        light: "#333",
      },
    },
  },
  ptBR
);
