import {
  Collapse,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Fragment, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Row as TanstackRow, Table, flexRender } from "@tanstack/react-table";
import { Action } from "./paginated-table";

export interface ViewDetailsComponentProps<T> {
  row: T;
}

export type ViewDetailsComponent<T> = React.FunctionComponent<
  ViewDetailsComponentProps<T>
>;

export interface RowsProps<T> {
  table: Table<T>;
  style?: React.CSSProperties;
  actions?: Action<T>[];
  details: ViewDetailsComponent<T>;
}

export interface RowProps<T> {
  row: TanstackRow<T>;
  alternate: boolean;
  actions?: Action<T>[];
  details: ViewDetailsComponent<T>;
}

export const Row = <T,>({ row, actions, details, alternate }: RowProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow
        sx={(theme) => ({
          background: alternate
            ? "rgb(240,240,240)"
            : theme.palette.background.paper,
          "@media (max-width: 800px)": {
            display: "flex", // Estilo para largura >= 800px
            width: "100%",
            flexDirection: "column",
          },
        })}
      >
        <TableCell>
          <TableCell
            sx={{
              borderBottom: "none",
            }}
            aria-label="Visualizar"
            size="small"
            align="center"
            onClick={() => setOpen(!open)}
          >
            <IconButton>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {actions?.map((action) => (
            <Tooltip key={action.tooltip} title={action.tooltip}>
              <TableCell
                sx={{
                  borderBottom: "none",
                }}
                aria-label={action.tooltip}
                size="small"
                align="center"
                onClick={(e) => {
                  if (action.onClick) {
                    action.onClick(e, row.original);
                  }
                }}
              >
                <IconButton color={action.color}>{action.icon}</IconButton>
              </TableCell>
            </Tooltip>
          ))}
        </TableCell>
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {details?.({ row: row.original })}
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export const Rows = <T,>({ table, style, actions, details }: RowsProps<T>) => {
  return (
    <TableBody sx={{}} style={style}>
      {table.getRowModel().rows.map((row, index) => (
        <Row
          key={row.id}
          row={row}
          actions={actions}
          details={details}
          alternate={index % 2 === 0}
        />
      ))}
    </TableBody>
  );
};
