import { IconButton, Link, Skeleton, TextField, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Fragment, useCallback, useState } from "react";
import { toast } from "react-toastify";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useLogger } from "usecases/logger";
import { parsePhone } from "common";

export interface PrintInformationProps {
  label: string;
  value: string;
  fullWidth: boolean;
  style?: React.CSSProperties;
  link?: boolean;
  whatsapp?: string;
  refresh?: () => Promise<string> | undefined;
}

export const PrintInformation = ({
  value,
  label,
  fullWidth,
  style,
  link,
  whatsapp,
  refresh,
}: PrintInformationProps) => {
  const number = parsePhone(whatsapp);
  const [isLoading, setLoading] = useState(false);
  const logger = useLogger();

  const getValue = useCallback(() => {
    return refresh
      ? refresh()
          ?.then((response) => response)
          .catch((err) => {
            logger.error(err.message);
            toast(
              `O servidor não está respondendo, tente novamente mais tarde.`,
              {
                type: "error",
              }
            );

            return "";
          })
      : value;
  }, [logger, refresh, value]);

  const copyValue = useCallback(async () => {
    setLoading(true);
    const text = await getValue();

    await navigator.clipboard.writeText(text || "");

    if (!!text) {
      toast(`${text} foi copiado!`, {
        type: "info",
      });
    } else {
      toast(`O servidor não está respondendo, tente novamente mais tarde.`, {
        type: "error",
      });
    }

    setLoading(false);
  }, [getValue]);

  const sentToWhatsapp = useCallback(async () => {
    setLoading(true);
    const text = await getValue();

    if (text) {
      const message = `Olá aqui está seu ${label}, \n\n\n${text}`;
      window.open(
        `https://api.whatsapp.com/send/?phone=${number}&text=${message}`
      );
    } else {
      toast(`O servidor não está respondendo, tente novamente mais tarde.`, {
        type: "error",
      });
    }

    setLoading(false);
  }, [number, label, getValue]);

  if (isLoading) {
    return <Skeleton variant="text" sx={{ fontSize: "1rem" }} />;
  }

  if (link) {
    return (
      <div>
        <Link
          margin="dense"
          aria-label={label}
          href={value}
          target="_blank"
          color="primary"
        >
          {label}
        </Link>
        <Tooltip title="Copiar">
          <IconButton onClick={copyValue} edge="end">
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
        {whatsapp && (
          <Tooltip title="Enviar por whatsapp">
            <IconButton
              sx={{
                marginLeft: "5px",
              }}
              aria-label="enviar por whatsapp"
              edge="end"
              onClick={sentToWhatsapp}
            >
              <WhatsAppIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    );
  } else {
    return (
      <TextField
        variant="standard"
        margin="dense"
        value={value}
        label={<b>{label}</b>}
        disabled
        fullWidth={fullWidth}
        style={style}
        InputProps={{
          endAdornment: (
            <Fragment>
              <Tooltip title="Copiar">
                <IconButton onClick={copyValue} edge="end">
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
              {whatsapp && (
                <Tooltip title="Enviar por whatsapp">
                  <IconButton
                    sx={{
                      marginLeft: "5px",
                    }}
                    aria-label="enviar por whatsapp"
                    edge="end"
                    onClick={sentToWhatsapp}
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Fragment>
          ),
        }}
      />
    );
  }
};
