import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginInput, useLogin } from "usecases/login";
import { LoginSchema } from "./validation";

export const useLoginComponent = () => {
  const login = useLogin();
  const navigate = useNavigate();
  const [showOrHide, setShowOrHide] = useState(false);

  const formik = useFormik<LoginInput>({
    initialValues: {
      login: "lunar",
      senha: "08430020aA!",
    },
    validationSchema: LoginSchema,
    onSubmit: login.submit,
  });

  const handleShoworHide = useCallback(
    () => setShowOrHide((state) => !state),
    []
  );

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  useEffect(() => {
    async function redirectOrPutUser() {
      const isAuthenticated = await login.isAuthenticated();

      if (isAuthenticated) {
        navigate("/dashboard", {
          replace: true,
          relative: "route",
          state: {
            title: "Visualizando contratos",
          },
        });
      }
    }

    redirectOrPutUser();
  }, [login, navigate]);

  return {
    handleMouseDownPassword,
    handleShoworHide,
    formik,
    showOrHide,
    login,
  };
};
